// Here you can add other styles

.c-app {
    
    font-size: 0.875rem !important;
   
}

.c-sidebar {
    width: 230px;
}

@media (min-width: 992px)
{
html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 230px;
}
}

.c-main {
    padding-top: 0.5rem !important;
}